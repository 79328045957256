.ck-editor {
    max-width: 50%;
}

textarea {
    margin-left: 50px;
}

.remove-btn {
    margin-left: 50px;
    padding: 10px 20px;
    max-height: 50px;
}
